/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import Bootstrap from "../../assets/images/backend/bootstrap.svg";
import Tailwind from "../../assets/images/backend/tailwindcss.svg";
import Html from "../../assets/images/backend/html5.svg";
import Css from "../../assets/images/backend/css.svg";
import Js from "../../assets/images/backend/javascript.svg";
export default function MobileTech() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">

          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://getbootstrap.com/" target="_blank">
              <img className="h-12" src={Bootstrap} alt="Bootstrap" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://tailwindcss.com/" target="_blank">
              <img className="h-12" src={Tailwind} alt="Tailwind css" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <a href="https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5" target="_blank">
              <img className="h-12" src={Html} alt="HTML5" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <a href="https://en.wikipedia.org/wiki/CSS" target="_blank">
              <img className="h-12" src={Css} alt="CSS3" />
            </a>
          </div>
          <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank">
              <img className="h-12" src={Js} alt="Javascript" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
